/* eslint-disable import/prefer-default-export */
import * as React from "react"
import TopLayout from "./TopLayout"
import { LayoutController } from "components/layouts/LayoutController"

export const wrapRootElement = ({ element }) => {
  return (
    <TopLayout>
      <LayoutController>{element}</LayoutController>
    </TopLayout>
  )
}
