import * as React from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { Box, Link } from "@mui/material"
import HamburgerMenu from "./HamburgerMenu"
import SBDLogoOnly from "../../assets/SlickBlueDragonLogoOnly.inline.svg"
import NavBar from "./NavBar"
import "./HamburgerMenu.css"
import { styled } from "@mui/system"

const HeaderOuterContainer = styled("div")(({ theme }) => ({
  margin: `0 auto`,
  width: "100%",
  background: theme.palette.primary.main,
  color: "white",
  padding: "0.5em 0.5em",
}))

const Header = ({
  siteTitle,
  toggleHamburger,
  setToggle,
  anchor,
  setAnchor,
}) => (
  <header>
    <HeaderOuterContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          maxHeight: "6em",
          fontSize: { xs: "1rem", sm: "1.2rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 1 100%",
          }}
        >
          <SBDLogoOnly
            style={{
              width: "30%",
              height: "30%",
              maxHeight: "3em",
              maxWidth: "3em",
              minHeight: "1em",
              minWidth: "2em",
              margin: "0.5em 1em 0 0",
            }}
          ></SBDLogoOnly>
          <Typography sx={{}} variant="body1" className="title">
            <Link
              href="/"
              sx={{
                color: `#B0C4E5`,
                marginLeft: "1em",
                fontSize: { xs: 20, sm: 30, md: 36 },
              }}
              underline="none"
            >
              {siteTitle}
            </Link>
          </Typography>
          <Typography sx={{ display: "none" }} variant="body1">
            ®
          </Typography>
        </Box>
        <NavBar></NavBar>
        <HamburgerMenu
          anchor={anchor}
          setAnchor={setAnchor}
          toggleHamburger={toggleHamburger}
          setToggle={setToggle}
        ></HamburgerMenu>
      </Box>
    </HeaderOuterContainer>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  toggleHamburger: PropTypes.bool,
  setToggle: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: `Slick Blue Dragon`,
}

export default Header
